<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      @closed="onDialogClosed"
    >
      <div slot="title" style="color: #409eff">商务信息</div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <div class="mainList_operation_search" style="margin-bottom: 8px">
            <div class="search-title">
              <span class="title">商务名称:</span>
              <el-input
                v-model="parameter.businessName"
                placeholder="请输入商务名称"
                width="340px"
              ></el-input>
            </div>
            <div class="search-button">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-search"
                @click="pageChangeHandler(1)"
                >搜索</el-button
              >
              <el-button type="warning" size="medium" icon="el-icon-search" @click="handleReset"
                >清空</el-button
              >
            </div>
          </div>
          <el-table
            :data="expenditureList"
            style="width: 100%"
            max-height="400"
            border
            v-loading="loading"
            ref="selectTable"
          >
            <el-table-column
              align="center"
              prop="businessName"
              min-width="280"
              :show-overflow-tooltip="false"
              label="商务名称"
            ></el-table-column>
            <el-table-column
              align="center"
              min-width="180"
              :show-overflow-tooltip="false"
              prop="ownerUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contacts"
              label="经办人"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contactsPhone"
              label="经办人电话"
              width="110"
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="initiatorName"
              label="填报人"
              width="100"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="principalUserName"
              label="实际商务负责人"
              sortable
              width="150"
            ></el-table-column> -->
            <el-table-column
              align="center"
              width="110"
              sortable
              prop="createdDate"
              label="申请时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="businessType"
              sortable
              label="商务类型"
              width="120"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="status"
              label="状态"
              sortable
              width="160"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">暂存</span>
                <span v-if="scope.row.status == 1 && scope.row.type == 'XSH'"
                  >商务部高管审核中</span
                >
                <span v-if="scope.row.status == 1 && scope.row.type == 'ZGH'">董事长审核中</span>
                <span v-if="scope.row.status == 3 && scope.row.type == 'XSH'"
                  >商务部高管审核通过</span
                >
                <span v-if="scope.row.status == 3 && scope.row.type == 'ZGH'">董事长审核通过</span>
                <span v-if="scope.row.status == 5">已移交计划经营部</span>
                <span v-if="scope.row.status == 6">已创建项目</span>
                <!-- <span v-if="scope.row.status == 7">项目完结</span> -->
                <!-- <span v-if="scope.row.status == 8">商务失败</span> -->
                <span v-if="scope.row.status == 9">商务暂停</span>
                <span v-if="scope.row.status == 10">商务终结</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'XSH'">高管审核不通过</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'ZGH'"
                  >董事长审核不通过</span
                >
              </template>
            </el-table-column>

            <el-table-column label="操作" width="120" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="$emit('choiceDataChange', scope.row)"
                  :disabled="scope.row.Selected == 1"
                  >{{ scope.row.Selected == 1 ? '已选' : '选择' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChangeHandler"
            @size-change="handleSizeChange"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    ExpenditureAdvanceDetails: () => import('@/components/funds/expenditure-advance-details.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 60,
    },
    pitchOnList: {
      type: Array,
      default: function () {
        return []
      },
    },
    deleteIds: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      expenditureList: [],
      companyTypeList: [],
      saveArr: [],
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessName: null,
        isRelationBid: false,
      },
      dictData: {
        businessType: [],
      },
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.isDialog = newVal
      },
    },
  },
  computed: {},
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.getData()
  },
  mounted() {},
  methods: {
    handleReset() {
      this.parameter.expenseNumber = null
      this.getData()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    getData() {
      this.loading = true
      this.$api.bid
        .getBusinessList(this.parameter)
        .then(res => {
          this.expenditureList = res.data?.records || []
          this.parameter.total = res.data?.total || 0
          this.loading = false
          if (this.deleteIds.length > 0) {
            // 说明存在有id的被删除
            this.expenditureList = [...this.expenditureList, ...this.deleteIds]
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    onDialogClosed() {
      this.$emit('closeBusinessDialog', false)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-table-column--selection {
  .is-disabled {
    .el-checkbox__inner::before {
      content: '✓'; /* 使用 Unicode 打勾字符 */
      font-size: 12px; /* 自定义字体大小 */
      position: absolute;
      color: #c0c4cc;
      left: 2px;
      top: 0;
    }
  }
}

.mainList_operation_search {
  display: flex;
  align-items: center;
  padding-right: 20px;
  .search-title {
    display: flex;
    align-items: center;
    .title {
      display: inline-block;
      width: 100px;
    }
  }
  .search-button {
    margin-left: 20px;
  }
}

.el-pagination {
  margin-top: 10px;
  text-align: right;
}
</style>
